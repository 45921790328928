a {
  color: inherit;
  text-decoration: none;
  background-color: inherit;
}
img {
  display: inline-flex;
  vertical-align: middle;
}
html, body {
  display: inline-block;
  height: 100%;
  overflow-y:hidden;
}
* {
  -webkit-tap-highlight-color: transparent;
}

@media only screen and (min-width: 400px) {
    .HeaderPage-title {
      position: absolute!important;
      left: 0!important;
      display: inline-block!important;
      padding-left: 20px!important;
    }
    .HeaderPage-header-bar{
      float: right!important;
      margin-right: 60px!important;
    }
}

.App {
  position: fixed;
  height: 100%;
  width: 100%;
}

.material-icons {
  font-size: inherit!important;
  display: inline-flex!important;
  vertical-align: middle!important;
}


.NotificationPage {
  display: inline-block;
  position: fixed;
  height: auto;
  width: calc(100% - 10px);
  left: 5px;
  background-color: #eceff1;
  z-index: 99999;
  overflow-y: auto;
  bottom: 0;
  box-shadow: 0 3px 3px 0 rgba(0,0,0,0.20), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
  animation: animateright .3s;
}
