
@media only screen and (max-width: 600px) {
    .SplitLeft {
        display: none;
    }
    .BoxComp-Box {
      position: absolute!important;
      height: 100%!important;
      width: 100%!important;
      border-radius: 0!important;
      max-height: 100%!important;
      max-width: 100%!important;
      top: 0!important;
      left: 0!important;
    }
}

.Page {
  display: inline-block;
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #eceff1;
  animation: animateright .3s;
}

.Page-Root {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.Page .Page {
  position: absolute;
  top: 0;
  left: 0;
}

.Page-Block {
  background-color: white;
  position: relative;
  box-sizing: border-box;
  text-align: left;
  height: auto;
  width: 100%;
  display: inline-block;
  padding: 0px;
  border-top: 1px solid #eceff1;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.07), 0 3px 1px -2px rgba(0,0,0,0.06), 0 1px 5px 0 rgba(0,0,0,0.1);
  margin-bottom: 3px;
  color: #263238;
  transition: .3s;
}

.Page-Block > *:nth-child(even) {
  background-color: #FAFAFA;
}

.Page-Block:hover {
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
}

.Page-Row {
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
  position: relative;
  box-sizing: border-box;
  text-align: left;
  min-height: 30px;
  border-bottom: 1px solid #eceff1;
  user-select: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  transition: .3s;
  background-color: inherit;
}

.Page-Row-Button {
  box-sizing: border-box;
  user-select: none;
  cursor: pointer;
  text-align: center;
  width: 100%;
  font-size: 16px;
  line-height: 30px;
  height: 30px;
  vertical-align: middle;
}
.Page-Row:hover {
  background: #ECEFF1;
}
.Page-Row-Text h1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 25px;
  margin: 0 0 10px 0;
  font-weight: 600;
}
.Page-Row-Text h2 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  margin: 0;
  font-weight: 600;
}
.Page-Row-Text input {
  outline: none;
  font-size: 16px;
  border: none;
  background: none;
  padding: 0;
  width: 100%;
}
.Page-Row-Text p {
  font-size: 14px;
  margin: 0;

}
.Page-Row-Text {
  width: 100%;
}
.Page-Row-ThumbnailText-Head {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 45px;
  min-width: 45px;
  min-height: 45px;
  border-radius: 30px;
  border: 3px solid #263238;
  margin: 0;
  box-shadow: 3px 3px 0 rgba(38, 50, 56, 0.3), inset 3px 3px 0 rgba(38, 50, 56, 0.3);
  background-color: white;
  transition: .3s;
}
.Page-Row-ThumbnailText-Head-img {
  max-width:100%;
  max-height:100%;
  border-radius: 30px;
}
.Page-Row-ThumbnailText-Head-Active-Circle {
  display: block;
  position: absolute;
  border: 3px solid #263238;
  width: 15px;
  height: 15px;
  right: -5px;
  bottom: -5px;
  border-radius: 30px;
  box-shadow: 3px 3px 0 rgba(38, 50, 56, 0.3);
  background-color: #1de9b6;
}

.Page-Row-ThumbnailText-Head-Active {
  display: block;
  position: absolute;
  border: 3px solid #263238;
  min-width: 15px;
  height: 15px;
  width: auto;
  font-size: 10px;
  font-weight: bold;
  line-height: 15px;
  padding: 0 2px;
  right: -5px;
  bottom: -5px;
  border-radius: 30px;
  box-shadow: 3px 3px 0 rgba(38, 50, 56, 0.3);
  background-color: #1de9b6;
}

.Page-Row-ThumbnailText-Head:hover {
  box-shadow: 3px 3px 0 rgba(38, 50, 56, 0.5), inset 3px 3px 0 rgba(38, 50, 56, 0.3);
}

.Page-Row-ThumbnailText-Text {
  max-width: 100%;
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  padding-left: 10px;
  width: auto;
  vertical-align: middle;
  flex-direction: column;
}

.Page-Row-ThumbnailText-Text h2 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  margin: 0;
  width: 100%;
  height: auto;
  font-size: 19px;
}

.Page-Row-ThumbnailText-Text p {
  display: block;
  margin: 0;
  width: 100%;
  height: auto;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.BackPage {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  background: #ECEFF1;
  height: 100%;
  width: 100%;
  font-size: 24px;
  animation: animateright .3s;
}
.BackPage::before {
  display: block;
  position: relative;
  content: '';
  height: 50px;
}


.BackPage-Header {
  color: white;
  top: 0;
  height: 50px;
  width: 100%;
  z-index: 99999;
  line-height: 50px;
  border-bottom: 1px solid #ECEFF1;
  box-sizing: border-box;
  position: absolute;
  background: #37474F;
  user-select: none;
}
.BackPage-Back-Button {
  left: 0;
  position: absolute;
  height: inherit;
  padding: 0;
  width: 50px;
  box-sizing: border-box;
  font-size: 25px;
  cursor: pointer;
}

.BackPage-Contain{
  position: relative;
  height: calc(100% - 50px);
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;

}

.BoxComp {
  display: inline-block;
  top: 60px;
  left: 0;
  position: fixed;
  height: calc( 100% - 60px);
  width: 100%;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.75);
  animation: turnblack .2s;
}
@media only screen and (max-width: 400px) {
    .BoxComp {
      top: 90px;
      height: calc( 100% - 90px);
    }
}
@keyframes turnblack {
  0% {
    background: rgba(0, 0, 0, 0.0);
  }
  100% {
    background: rgba(0, 0, 0, 0.75);
  }
}
.BoxComp-Box {
  top: 60px;
  overflow: auto;
  background: white;
  display: inline-block;
  position: relative;
  height: calc(100% - 120px);
  width: 70%;
  max-height: 700px;
  min-width: 300px;
  max-width: 500px;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
  animation-name: animatebottom;
  animation-duration: .2s;
}
.BoxComp-Box * {
  animation: none;
}
.BoxComp-Back {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
@keyframes animatebottom {
  0% {
    opacity: .6;
    transform: translate(0, 30px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.SplitComp {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
}

.SplitLeft{
  position: relative;
  height: 100%;
  float: left;
  order: 0;
  flex-grow: 1;
  overflow-y: none;
  box-sizing: border-box;
  max-width: 350px;
  border-right: 1px solid #ECEFF1;
}

.SplitRight {
  position: relative;
  height: 100%;
  float: left;
  order: 1;
  flex-grow: 3;
}
.SplitRight >div {
  animation: animateright .3s;
}
@-webkit-keyframes animateright {
  0% {
    opacity: .6;
    width: calc(100% - 8px);
    transform: translate(8px, 0);
  }
  100% {
    opacity: 1;
    width: 100%;
    transform: translate(0, 0);
  }
}
